import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "./partials/Header";
function UtentiRegistrati() {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Aggiunto stato per il caricamento
  const [error, setError] = useState(null); // Aggiunto stato per gli errori
  const navigate = useNavigate();
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const initialPage = parseInt(pageParam) || 1;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [recordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/iPanel/"); // Reindirizza alla pagina di login
  };

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSearchParams({ page: pageNumber });
  };

  const openModal = (record) => {
    setSelectedRecord(record);
  };
  useEffect(() => {
    const fetchRecords = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/iPanel/");
        return;
      }
      try {
        setIsLoading(true); // Imposta il caricamento a true
        setError(null); // Resetta eventuali errori precedenti
        const response = await fetch(
          `/api/utenti?page=${currentPage}&limit=${recordsPerPage}`,
          {
            headers: {
              "Cache-Control": "no-cache",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          // Gestisci gli errori della chiamata API
          const message = `Errore nella chiamata API: ${response.status} ${response.statusText}`;
          throw new Error(message);
        }
        const data = await response.json();
        setRecords(data.records); // Aggiorna lo stato con i dati ricevuti
        setTotalPages(Math.ceil(data.records.length / recordsPerPage));
        setTotalRecords(data.records.length); // Imposta il numero totale di record
      } catch (error) {
        console.error("Errore durante il recupero dei record:", error);
        setError(error); // Imposta lo stato dell'errore
      } finally {
        setIsLoading(false); // Imposta il caricamento a false in ogni caso
      }
    };

    fetchRecords();
  }, [currentPage, recordsPerPage, navigate]); // Includi currentPage nelle dipendenze di useEffect

  // Logica per la paginazione (solo se records è definito e non vuoto)
  const currentRecords =
    records && records.length > 0
      ? records.slice(
          (currentPage - 1) * recordsPerPage,
          currentPage * recordsPerPage
        )
      : [];

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Header />
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 mt-3 mb-3 p-3">
            <div className="card">
              <div className="card-body">
                <h1>
                  <button
                    type="submit"
                    onClick={handleLogout}
                    className="btn btn-sm btn-danger float-end"
                  >
                    <i class="fa-solid fa-arrow-right-from-bracket"></i> Esci
                  </button>
                  Utenti Registrati
                </h1>
                <div className="alert alert-info mb-3">
                  Clicca sul record per vedere i dettagli.
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th style={{ fontSize: "0.8rem" }}>Cognome & Nome</th>
                        <th style={{ fontSize: "0.8rem" }}>Nazione</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentRecords.map((record) => (
                        <tr key={record._id} onClick={() => openModal(record)}>
                          <td style={{ fontSize: "0.9rem" }}>
                            {record.cognome || ""} {record.nome || ""}
                          </td>
                          <td style={{ fontSize: "0.9rem" }}>
                            {record.nazione || "-"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* Paginazione */}
                <div className="row">
                  <div
                    className="col-md-6 col-sm-12"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Totale record: {totalRecords}
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <style></style>
                    <ul className="pagination">
                      {/* Pulsante "Prec." */}
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <a
                          onClick={() => paginate(currentPage - 1)}
                          href={`/iPanel/utenti-registrati?page=${currentPage}`}
                          className={`btn btn-sm ${
                            currentPage === 1 ? "btn-light" : "btn-dark"
                          }`}
                          style={{
                            marginRight: "5px",
                            color: "white",
                            fontWeight: "500",
                          }}
                        >
                          Prec.
                        </a>
                      </li>

                      {/* Pagina 1 */}
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "active" : ""
                        }`}
                      >
                        <a
                          onClick={() => paginate(1)}
                          href={`/iPanel/utenti-registrati?page=1`}
                          className={`btn btn-sm ${
                            currentPage === 1 ? "btn-light" : "btn-dark"
                          }`}
                          style={{
                            marginRight: "5px",
                            color: "white",
                            fontWeight: "500",
                          }}
                        >
                          1
                        </a>{" "}
                      </li>

                      {/* Numeri di pagina precedenti alla pagina corrente */}
                      {currentPage > 3 && (
                        <>
                          <li className="page-item">
                            <span className="btn btn-sm btn-light">...</span>
                          </li>
                          {currentPage > 3 && (
                            <li
                              className={`page-item ${
                                currentPage === currentPage - 2 ? "active" : ""
                              }`}
                            >
                              <a
                                onClick={() => paginate(currentPage - 2)}
                                href={`/iPanel/utenti-registrati?page=${
                                  currentPage - 2
                                }`}
                                className={`btn btn-sm ${
                                  currentPage === currentPage - 2
                                    ? "btn-light"
                                    : "btn-dark"
                                }`}
                                style={{
                                  marginRight: "5px",
                                  color: "white",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPage - 2}
                              </a>
                            </li>
                          )}
                          {currentPage > 2 && (
                            <li
                              className={`page-item ${
                                currentPage === currentPage - 1 ? "active" : ""
                              }`}
                            >
                              <a
                                onClick={() => paginate(currentPage - 1)}
                                href={`/iPanel/utenti-registrati?page=${
                                  currentPage - 1
                                }`}
                                className={`btn btn-sm ${
                                  currentPage === currentPage - 1
                                    ? "btn-light"
                                    : "btn-dark"
                                }`}
                                style={{
                                  marginRight: "5px",
                                  color: "white",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPage - 1}
                              </a>
                            </li>
                          )}
                        </>
                      )}

                      {/* Pagina corrente */}
                      {currentPage > 1 && currentPage < totalPages && (
                        <li className="page-item active">
                          <a
                            onClick={() => paginate(currentPage)}
                            href={`/iPanel/utenti-registrati?page=${currentPage}`}
                            className="btn btn-sm btn-light"
                            style={{
                              marginRight: "5px",
                              color: "white",
                              fontWeight: "500",
                            }}
                          >
                            {currentPage}
                          </a>
                        </li>
                      )}

                      {/* Numeri di pagina successivi alla pagina corrente */}
                      {currentPage < totalPages - 2 && (
                        <>
                          {currentPage < totalPages - 1 && (
                            <li
                              className={`page-item ${
                                currentPage === currentPage + 1 ? "active" : ""
                              }`}
                            >
                              <a
                                onClick={() => paginate(currentPage + 1)}
                                href={`/iPanel/utenti-registrati?page=${
                                  currentPage + 1
                                }`}
                                className={`btn btn-sm ${
                                  currentPage === currentPage + 1
                                    ? "btn-light"
                                    : "btn-dark"
                                }`}
                                style={{
                                  marginRight: "5px",
                                  color: "white",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPage + 1}
                              </a>
                            </li>
                          )}
                          {currentPage < totalPages - 2 && (
                            <li
                              className={`page-item ${
                                currentPage === currentPage + 2 ? "active" : ""
                              }`}
                            >
                              <a
                                onClick={() => paginate(currentPage + 2)}
                                href={`/iPanel/utenti-registrati?page=${
                                  currentPage + 2
                                }`}
                                className={`btn btn-sm ${
                                  currentPage === currentPage + 2
                                    ? "btn-light"
                                    : "btn-dark"
                                }`}
                                style={{
                                  marginRight: "5px",
                                  color: "white",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPage + 2}
                              </a>
                            </li>
                          )}
                          <li className="page-item">
                            <span className="btn btn-sm btn-light">...</span>
                          </li>
                        </>
                      )}

                      {/* Ultima pagina */}
                      {totalPages > 1 && (
                        <li
                          className={`page-item ${
                            currentPage === totalPages ? "active" : ""
                          }`}
                        >
                          <a
                            onClick={() => paginate(totalPages)}
                            href={`/iPanel/utenti-registrati?page=${totalPages}`}
                            className={`btn btn-sm ${
                              currentPage === totalPages
                                ? "btn-light"
                                : "btn-dark"
                            }`}
                          >
                            {totalPages}
                          </a>
                        </li>
                      )}

                      {/* Pulsante "Succ" */}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <a
                          onClick={() =>
                            paginate(
                              currentPage === totalPages ? 1 : currentPage + 1
                            )
                          }
                          href={`/iPanel/utenti-registrati?page=${
                            currentPage === totalPages ? 1 : currentPage + 1
                          }`}
                          className={`btn btn-sm ${
                            currentPage === totalPages
                              ? "btn-light"
                              : "btn-dark"
                          }`}
                          style={{
                            marginRight: "5px",
                            color: "white",
                            fontWeight: "500",
                          }}
                        >
                          Succ.
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedRecord && (
        <RecordModal
          record={selectedRecord}
          onClose={() => setSelectedRecord(null)}
        />
      )}
    </>
  );

  function RecordModal({ record, onClose }) {
    return (
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-secondary">
                Dettagli dell'Utente registrato
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body text-dark">
              {/* Visualizza i dettagli del record */}
              <p>
                Cognome e Nome:{" "}
                <b>
                  {record.cognome} {record.nome}
                </b>
              </p>
              <p>
                Azienda: <b>{record.azienda || " n. d. "}</b>
                {record.settore && (
                  <>
                    <br />
                    <i>Settore</i>: <b>{record.settore}</b>
                  </>
                )}
                {record.ruolo && (
                  <>
                    <br />
                    <i>Ruolo</i>: <b>{record.ruolo}</b>
                  </>
                )}
              </p>
              <p>
                Nazione: <b>{record.nazione || "-"}</b>
              </p>
              {record.mail && (
                <p>
                  E-mail: <a href="mailto:{record.mail}">{record.mail}</a>
                </p>
              )}
              {record.tel && (
                <p>
                  Telefono: <b>{record.tel}</b>
                </p>
              )}
              {record.web && (
                <p>
                  Sito web:{" "}
                  <a
                    href="{record.web}"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {record.web}
                  </a>
                </p>
              )}
              <p>
                Settori di interesse:{" "}
                {Array.isArray(record.interessi) &&
                  record.interessi.map((interesse, index) => (
                    <span key={index} style={{ fontWeight: "bold" }}>
                      {interesse}
                      {index < record.interessi.length - 1 && ", "}
                    </span>
                  ))}
              </p>
              {record.descrizione && (
                <p>
                  Note: <b>{record.descrizione}</b>
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Chiudi
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UtentiRegistrati;
